/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { LoadingScreen, DeleteModal, PrintModal } from "../components";
import API from "../services/API";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const accountType = (type: string) => {
  switch (type) {
    case "weapon":
      return "تصريح حامل سلاح";
    case "wireless":
      return "حامل لاسلكي";
    case "vehicle":
      return "قيادة مركبة";
    default:
      return "غير معروف";
  }
};

const Home: React.FC = () => {
  const [loadingScreen, setLoadinSeccren] = useState<boolean>(true);
  const [loading, setLoadin] = useState<boolean>(false);
  const [searchVal, setSearchVal] = useState<string>("");
  const [data, setData] = useState<any>([]);
  const [page, setPage] = useState<number>(1);
  const [type, setType] = useState<string>("");
  const [meta, setMeta] = useState<any>(null);
  const [deletrLoading, setDeleteLoading] = useState<boolean>(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState<number | null>(
    null
  );
  const [printData, setPrintData] = useState(null);
  const navigate = useNavigate();

  const getData = async () => {
    try {
      const { data } = await API.getPermits(page, searchVal, type);
      setData(data.data);
      setMeta(data.meta);
      setLoadinSeccren(false);
      setLoadin(false);
    } catch (error) {
      setLoadinSeccren(false);
      setLoadin(false);
    }
  };

  useEffect(() => {
    getData();
  }, [page]);

  const search = () => {
    setLoadin(true);
    getData();
  };

  const Delete = async () => {
    if (!deleteConfirmation) return;
    try {
      await API.deletePermit(deleteConfirmation);
      toast.success("تم الحذف بنجاح");
      getData();
      setDeleteLoading(false);
      setDeleteConfirmation(null);
    } catch (error) {
      toast.error("حذث خطأ ما الرجاء إعادة المحاولة لاحقا");
      setDeleteLoading(false);
      setDeleteConfirmation(null);
    }
  };

  if (loadingScreen) {
    return <LoadingScreen />;
  }

  return (
    <>
      <div className="container mx-auto px-5 md:px-8 my-8">
        <section className="antialiased bg-gray-100 text-gray-600  p-4">
          <div className=" mx-auto bg-white shadow-lg rounded-sm border border-gray-200">
            <header className="px-5 py-4 border-b border-gray-100">
              <div className="flex items-center">
                <input
                  type="search"
                  placeholder="بحث"
                  value={searchVal}
                  name="search"
                  aria-label="بحث عن عضو ما"
                  aria-placeholder="بحث عن عضو ما"
                  onChange={(e) => setSearchVal(e.target.value)}
                  className="px-3 py-2 rounded-md border border-gray-400 w-3/4 sm:w-1/2 lg:w-1/4"
                />
                <div className="w-3/4 sm:w-1/2 lg:w-1/4 mx-5 flex item-center">
                  <select
                    name="type"
                    id="type"
                    onChange={(e) => setType(e.target.value)}
                    placeholder="نوع الحساب"
                    className=" px-2 py-3 rounded-md border border-gray-400 w-full h-full"
                  >
                    {[
                      { placeholder: "نوع الحساب", value: "wepon" },
                      { placeholder: " حامل سلاح", value: "weapon" },
                      { placeholder: "حامل لاسلكي", value: "wireless" },
                      { placeholder: "قيادة مركبة", value: "vehicle" },
                    ].map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.placeholder}
                      </option>
                    ))}
                  </select>
                  {!!type && (
                    <i
                      onClick={() => setType("")}
                      className="bx bx-x text-xl text-red-600 transform cursor-pointer translate-x-10 mt-2 inline-block"
                    ></i>
                  )}
                </div>
                <button
                  onClick={search}
                  className="bg-primary px-5 py-2 rounded-md  text-white flex items-center justify-center"
                >
                  {loading ? (
                    <i className="bx bx-loader-circle animate-spin"></i>
                  ) : (
                    <span>تأكيد</span>
                  )}
                </button>
              </div>
            </header>
            <div className="p-3">
              <div className="overflow-x-auto w-full ">
                {data.length > 0 ? (
                  <table className="table-auto w-full overflow-x-scroll">
                    <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
                      <tr>
                        {[
                          "الرقم الوطني",
                          "الاسم",
                          "اسم الاب",
                          "اسم الجد",
                          "اللقب",
                          "الرتبة",
                          "نوع الحسب",
                          "النوع",
                          "الرقم",
                          "الجهة",
                          "الوصف الوظيفي",
                          "القسم",
                          "تاريخ الاصدار",
                          "الصلاحية",
                          " ",
                        ].map((item, index) => (
                          <th className="p-2 whitespace-nowrap" key={index}>
                            <span className="font-semibold text-center text-lg">
                              {item}
                            </span>
                          </th>
                        ))}
                      </tr>
                    </thead>

                    <tbody className="text-sm divide-y divide-gray-100">
                      {data.map((item: any, index: number) => (
                        <tr key={index}>
                          <td className="p-2 whitespace-nowrap text-center">
                            {item.national_id}
                          </td>
                          <td className="p-2 whitespace-nowrap text-center">
                            {item.first_name}
                          </td>
                          <td className="p-2 whitespace-nowrap text-center">
                            {item.second_name}
                          </td>
                          <td className="p-2 whitespace-nowrap text-center">
                            {item.third_name}
                          </td>
                          <td className="p-2 whitespace-nowrap text-center">
                            {item.last_name}
                          </td>
                          <td className="p-2 whitespace-nowrap text-center">
                            {item.rank}
                          </td>
                          <td className="p-2 whitespace-nowrap text-center">
                            {accountType(item.type)}
                          </td>
                          <td className="p-2 whitespace-nowrap text-center">
                            {item.model}
                          </td>
                          <td className="p-2 whitespace-nowrap text-center">
                            {item.number}
                          </td>
                          <td className="p-2 whitespace-nowrap text-center">
                            {item.agency}
                          </td>
                          <td className="p-2 whitespace-nowrap text-center">
                            {item.job_description}
                          </td>
                          <td className="p-2 whitespace-nowrap text-center">
                            {item.department}
                          </td>
                          <td className="p-2 whitespace-nowrap text-center">
                            {item.created_at}
                          </td>
                          <td className="p-2 whitespace-nowrap text-center">
                            {item.expiry_date}
                          </td>
                          <td className="p-2  text-center flex justify-center items-center">
                            <button
                              onClick={() => {
                                setPrintData(item);
                              }}
                              className="mx-2 font-semibold text-center bg-primary  text-white px-3 py-2 rounded-lg"
                            >
                              طباعة
                            </button>
                            <button
                              onClick={() =>
                                navigate(`/form-screen/${item.id}`)
                              }
                              className="mx-2 font-semibold text-center text-primary border border-primary bg-white px-3 py-2 rounded-lg"
                            >
                              تعديل
                            </button>
                            <button
                              onClick={() => {
                                setDeleteConfirmation(item.id);
                              }}
                              className="mx-2 font-semibold text-center bg-red-500 text-white px-3 py-2 rounded-lg"
                            >
                              حدف
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="text-center font-medium text-2xl mt-5 mx-auto">
                    لا يوجد نتائج
                  </div>
                )}
              </div>
            </div>
            {!!meta && (
              <div className="flex items-center justify-center my-5">
                <Pagination
                  count={meta.last_page}
                  variant="outlined"
                  shape="rounded"
                  page={page}
                  onChange={(e, vlaue) => setPage(+vlaue)}
                  renderItem={(item) => (
                    <PaginationItem
                      components={{
                        previous: () => (
                          <i className="bx bx-chevron-right text-2xl"></i>
                        ),
                        next: () => (
                          <i className="bx bx-chevron-left text-2xl"></i>
                        ),
                      }}
                      {...item}
                    />
                  )}
                />
              </div>
            )}
          </div>
        </section>
      </div>
      {!!deleteConfirmation && (
        <DeleteModal
          close={() => {
            setDeleteConfirmation(null);
          }}
          title="حذف عضو"
          description="هل انت متأكد حقا انك تريد حذق هذا العضو"
          loading={deletrLoading}
          confirm={Delete}
        />
      )}
      {!!printData && (
        <PrintModal printData={printData} close={() => setPrintData(null)} />
      )}
    </>
  );
};

export default Home;
