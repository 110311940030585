import * as yup from "yup";

//const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const loginSchema = yup.object().shape({
  email: yup.string().email().required("يجب كتابة البريد الالكتروني"),
  password: yup.string().required("يجب كتابة كلمة المرور لتسجيل الدخول"),
});

export const formSchema = yup.object().shape({
  first_name: yup
    .string()
    .required("يجب كتابة الاسم الاول")
    .max(10, "الرجاء كتابة الاسم الحقيقي")
    .min(2, "الرجاء كتابة الاسم الحقيقي"),
  second_name: yup
    .string()
    .required("يجب كتابة اسم الاب")
    .max(10, "الرجاء كتابة الاسم الحقيقي")
    .min(2, "الرجاء كتابة الاسم الحقيقي"),
  third_name: yup
    .string()
    .required("يجب كتابة اسم الجد")
    .max(10, "الرجاء كتابة الاسم الحقيقي")
    .min(2, "الرجاء كتابة الاسم الحقيقي"),
  last_name: yup
    .string()
    .required("يجب كتابة اللقب")
    .max(10, "الرجاء كتابة اللقب الحقيقي")
    .min(2, "الرجاء كتابة اللقب الحقيقي"),
  national_id: yup
    .string()
    .required("يجب كتابة الرقم الوطني")
    .max(
      12,
      "الرجاء التأكد من صحة الرقم الوطني، يجب ان يتكون الرقم الوطني من 12 رقم"
    )
    .min(
      12,
      "الرجاء التأكد من صحة الرقم الوطني، يجب ان يتكون الرقم الوطني من 12 رقم"
    ),
  type: yup.string().required("يجب تحديد نوع التسجيل"),
  rank: yup.string().required("يجب تحديد الرتبة"),
  profile_picture: yup.string().required("يجب تحميل الصورة الشخصية"),
  expiry_date: yup.string().required("يجب تحديد صلاحية البطاقة"),
  model: yup.string().required("يجب كتابة النوع"),
  number: yup.string().required("يجب كتابة الرقم"),
  agency: yup.string().required("يجب كتابة الجهة"),
  job_description: yup.string().required("يجب كتابة الوصف الوظيفي"),
  department: yup.string().required("يجب كتابة القسم"),
});
