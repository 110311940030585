import { useRef } from "react";
import ReactToPrint from "react-to-print";

interface props {
  close: () => void;
  printData: any;
}

const PrintModal: React.FC<props> = ({ close, printData }) => {
  let componentRef: any = useRef(null);

  const accountType = (type: string) => {
    switch (type) {
      case "weapon":
        return "حامل سلاح";
      case "wireless":
        return "حامل لاسلكي";
      case "vehicle":
        return "قيادة مركبة";
      default:
        return "غير معروف";
    }
  };

  const getBG = (type: string) => {
    switch (type) {
      case "weapon":
        return "wave-red-bg";
      case "wireless":
        return "wave-blue-bg";
      case "vehicle":
        return "wave-yellow-bg";
      default:
        return "";
    }
  };

  return (
    <div
      className="fixed z-10 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-center justify-center h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity"
          aria-hidden="true"
        ></div>
        {/*  */}
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            {/*  print data */}
            <div
              ref={(el: any) => (componentRef = el)}
              id="print-data"
              className="p-5"
            >
              {/* front */}
              <div
                className={`${getBG(
                  printData.type
                )} grid grid-cols-4 text-main p-2 text-sm mx-auto  border border-gray-200`}
                style={{ height: "207.87px", width: "321.26px" }}
              >
                <div className="col-span-1 text-center">
                  <img
                    src="https://moi.gov.ly/wp-content/uploads/2021/09/Picture1.png"
                    alt=""
                    className="h-12 object-contain bg-center mx-auto"
                  />
                  <div className="mt-3">
                    <h6 className="my-1">الإسم:</h6>
                    <h6 className="my-1">الرتبة:</h6>
                    <h6 className="my-1">الجهة:</h6>
                    <h6 className="my-1">النوع:</h6>
                    <h6 className="my-1">الرقم:</h6>
                  </div>
                </div>
                <div className="col-span-3">
                  <h1 className="h-12 w-full text-center font-bold">
                    دولة ليبيا - ورازة الداخلية <br /> مصلحة الجوازات والجنسية
                    وشؤون الاجانب
                  </h1>
                  <div className="grid grid-cols-3 w-full mt-3 text-center">
                    <div className="col-span-2">
                      <h6 className="my-1">{`${printData.first_name} ${printData.second_name} ${printData.third_name} ${printData.last_name}`}</h6>
                      <h6 className="my-1">{printData.rank}</h6>
                      <h6 className="my-1">{printData.agency}</h6>
                      <h6 className="my-1">{printData.model}</h6>
                      <h6 className="my-1">{printData.number}</h6>
                    </div>
                    <img
                      className="col-span-1 h-full w-full object-center mx-auto"
                      src={printData.picture[0].path}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              {/* back */}
              <div
                className="mt-8 flex justify-center items-center text-main p-2 text-sm mx-auto wave-bg border border-gray-200"
                style={{ height: "207.87px", width: "321.26px" }}
              >
                <div>
                  <h1 className="text-lg font-bold mb-4">
                    تعريف {accountType(printData.type)}
                  </h1>
                  <img
                    src="https://moi.gov.ly/wp-content/uploads/2021/09/Picture1.png"
                    alt=""
                    className="h-24 object-contain bg-center mx-auto"
                  />
                </div>
              </div>
            </div>

            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <ReactToPrint
                trigger={() => (
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    طباعة
                  </button>
                )}
                content={() => componentRef}
              />
              {/* <button
                type="button"
                onClick={confirm}
                disabled={loading}
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
              >
                طباعة
              </button> */}
              <button
                type="button"
                onClick={close}
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                تراجع
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrintModal;
