/* eslint-disable react-hooks/exhaustive-deps */
import { Formik, Form } from "formik";
import { useEffect, useRef, useState } from "react";
import { formSchema } from "../services/validation";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { LoadingScreen } from "../components";

import API from "../services/API";

const FormScreen: React.FC = () => {
  const [loadingScreen, setLoadinSeccren] = useState<boolean>(false);
  const uploadImage: any = useRef(null);
  const [data, setData] = useState<any>();
  const navigate = useNavigate();
  const [error, setError] = useState<string>("");
  const params = useParams();

  const getData = async () => {
    if (!params.id) {
      toast.error("هذا المساخدم غير موجود");
      return;
    }
    setLoadinSeccren(true);
    try {
      const { data } = await API.getSpecificPermits(params.id);
      setData(data.data);
      setLoadinSeccren(false);
    } catch (error) {
      setLoadinSeccren(false);
    }
  };

  useEffect(() => {
    if (params.id) {
      getData();
    }
  }, []);

  const hanldeUploadImage = () => {
    uploadImage?.current?.click();
  };

  if (loadingScreen) {
    return <LoadingScreen />;
  }

  return (
    <div className="container mx-auto px-5 md:px-8">
      <h1 className="text-3xl my-8">إضافة عضو جديد</h1>
      <Formik
        initialValues={
          {
            first_name: data ? data.first_name : "",
            second_name: data ? data.second_name : "",
            third_name: data ? data.third_name : "",
            last_name: data ? data.last_name : "",
            national_id: data ? data.national_id : 0,
            type: data ? data.type : "",
            rank: data ? data.rank : "",
            profile_picture: data ? data.picture : "",
            expiry_date: data ? data.expiry_date : 0,
            model: data ? data.model : "",
            number: data ? data.number : 0,
            job_description: data ? data.job_description : "",
            agency: data ? data.agency : "",
            department: data ? data.department : "",
          } as {
            first_name: string;
            second_name: string;
            third_name: string;
            last_name: string;
            national_id: number;
            type: string;
            rank: string;
            profile_picture: any;
            expiry_date: number;
            model: string;
            number: number;
            agency: string;
            department: string;
            job_description: string;
          }
        }
        validationSchema={formSchema}
        onSubmit={async (
          values,
          { setSubmitting, setErrors, setFieldError }
        ) => {
          try {
            const formData = new FormData();
            for (const [key, value] of Object.entries(values)) {
              formData.append(key, value);
            }
            if (data) {
              // that's mean we're in update mode
              formData.append("_method", "put");
              await API.editPermits(formData, data?.id);
            } else {
              await API.addPermits(formData);
            }
            setSubmitting(false);
            navigate("/");
            toast.success("تم الاضافة بنجاح");
          } catch (err: any) {
            toast.error("حدث خطأ ما");
            if (err.response.data.error) {
              setError(err.response.data.error);
            } else {
              setError("حدث خطأ ما الرجاء إعادة المحاولة لاحقا");
            }
            setSubmitting(false);
          }
        }}
      >
        {({
          values,
          errors,
          handleSubmit,
          handleChange,
          touched,
          setFieldValue,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="">
                <label htmlFor="first_name">الإسم</label>
                <input
                  placeholder="احمد"
                  value={values.first_name}
                  type="text"
                  id="first_name"
                  name="first_name"
                  onChange={handleChange("first_name")}
                  className="w-full my-1 px-3 py-2 rounded-md border border-gray-400"
                />
                {touched.first_name && errors.first_name && (
                  <p className="text-red-600">{errors.first_name}</p>
                )}
              </div>
              {/*  */}
              <div className="">
                <label htmlFor="second_name">اسم الاب</label>
                <input
                  placeholder="خليفة"
                  value={values.second_name}
                  type="text"
                  id="second_name"
                  name="second_name"
                  onChange={handleChange("second_name")}
                  className="w-full my-1 px-3 py-2 rounded-md border border-gray-400"
                />
                {touched.second_name && errors.second_name && (
                  <p className="text-red-600">{errors.second_name}</p>
                )}
              </div>
              {/*  */}
              <div className="">
                <label htmlFor="third_name">اسم الجد</label>
                <input
                  placeholder="صالح"
                  value={values.third_name}
                  type="text"
                  id="third_name"
                  name="third_name"
                  onChange={handleChange("third_name")}
                  className="w-full my-1 px-3 py-2 rounded-md border border-gray-400"
                />
                {touched.third_name && errors.third_name && (
                  <p className="text-red-600">{errors.third_name}</p>
                )}
              </div>
              {/*  */}
              <div className="">
                <label htmlFor="last_name">اللقب</label>
                <input
                  placeholder="احمد"
                  value={values.last_name}
                  type="text"
                  id="last_name"
                  name="last_name"
                  onChange={handleChange("last_name")}
                  className="w-full my-1 px-3 py-2 rounded-md border border-gray-400"
                />
                {touched.last_name && errors.last_name && (
                  <p className="text-red-600">{errors.last_name}</p>
                )}
              </div>
              {/*  */}
              <div className="">
                <label htmlFor="national_id">الرقم الوطني</label>
                <input
                  placeholder="12345678912"
                  value={values.national_id}
                  type="number"
                  id="national_id"
                  name="national_id"
                  onChange={handleChange("national_id")}
                  className="w-full my-1 px-3 py-2 rounded-md border border-gray-400"
                />
                {touched.national_id && errors.national_id && (
                  <p className="text-red-600">{errors.national_id}</p>
                )}
              </div>
              {/*  */}
              <div className="">
                <label htmlFor="national_id">المسمى الوضيفي</label>
                <input
                  placeholder="اداري"
                  value={values.job_description}
                  type="string"
                  id="job_description"
                  name="job_description"
                  onChange={handleChange("job_description")}
                  className="w-full my-1 px-3 py-2 rounded-md border border-gray-400"
                />
                {touched.job_description && errors.job_description && (
                  <p className="text-red-600">{errors.job_description}</p>
                )}
              </div>
              {/*  */}
              <div className="">
                <label htmlFor="agency">الجهة</label>
                <input
                  placeholder="فرع جوازات مصراتة"
                  value={values.agency}
                  type="string"
                  id="agency"
                  name="agency"
                  onChange={handleChange("agency")}
                  className="w-full my-1 px-3 py-2 rounded-md border border-gray-400"
                />
                {touched.agency && errors.agency && (
                  <p className="text-red-600">{errors.agency}</p>
                )}
              </div>
              {/*  */}
              <div className="">
                <label htmlFor="department">القسم</label>
                <input
                  placeholder="الشؤون الإدارية"
                  value={values.department}
                  type="string"
                  id="department"
                  name="department"
                  onChange={handleChange("department")}
                  className="w-full my-1 px-3 py-2 rounded-md border border-gray-400"
                />
                {touched.department && errors.department && (
                  <p className="text-red-600">{errors.department}</p>
                )}
              </div>
              {/*  */}
              <div className="">
                <label htmlFor="rank">الرتبة</label>
                <select
                  id="rank"
                  name="rank"
                  onChange={handleChange("rank")}
                  className="w-full my-1 px-3 py-2 rounded-md border border-gray-400"
                >
                  <option disabled value="">
                    الرتبة
                  </option>
                  {[
                    "",
                    "ضابط صف",
                    "موظف",
                    "عقيد",
                    "عميد",
                    "لواء",
                    "مقدم",
                    "رائد",
                    "ملازم",
                    "ملازم اول",
                    "نقيب"
                  ].map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </select>

                {touched.rank && errors.rank && (
                  <p className="text-red-600">{errors.rank}</p>
                )}
              </div>
              {/*  */}
              {!!data &&
              typeof values?.profile_picture == "object" &&
              values?.profile_picture[0]?.path ? (
                <div>
                  <label htmlFor="profile_picture">الصورة الشخصية</label>
                  <div className="w-36 h-42 rounded-md border border-gray-400 relative">
                    <img
                      src={values?.profile_picture[0]?.path}
                      alt=""
                      loading="lazy"
                      className="object-cover h-full w-full"
                    />
                    <button
                      onClick={() => setFieldValue("profile_picture", "")}
                      className="h-5 w-5 rounded-full font-bold absolute -top-2 -right-2 bg-black flex items-center justify-center"
                    >
                      <i className="bx bx-x text-white"></i>
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <label htmlFor="profile_picture">الصورة الشخصية</label>
                  <button
                    onClick={hanldeUploadImage}
                    className="flex items-center w-full my-1 px-2 py-1 rounded-md border border-gray-400"
                  >
                    <span className="px-4 bg-primary py-1 rounded-md text-white">
                      تحميل
                    </span>
                    <p className="mx-2">
                      {values.profile_picture
                        ? values.profile_picture?.name
                        : "تحميل الصورة الشخصية"}
                    </p>
                  </button>
                  <input
                    ref={uploadImage}
                    type="file"
                    id="profile_picture"
                    className="hidden"
                    onChange={(e: any) => {
                      if (!e.target.files.length) return;
                      const type = e.target.files[0].type;
                      if (
                        type === "image/jpeg" ||
                        type === "image/png" ||
                        type === "image/jpg" ||
                        type === "image/svg+xml" ||
                        type === "file/pdf"
                      ) {
                        setFieldValue("profile_picture", e.target.files[0]);
                      } else {
                        toast.warning(
                          "لا يمكن تحميل ملف بهذه الصيغة الرجاء التحقق من صيغة الملف وإعادة المحاولة"
                        );
                      }
                    }}
                  />
                  {touched.profile_picture && errors.profile_picture && (
                    <p className="text-red-600">{errors.profile_picture}</p>
                  )}
                </div>
              )}
              {/*  */}
              <div>
                <label htmlFor="type">نوع الحساب</label>
                <select
                  name="type"
                  id="type"
                  onChange={handleChange("type")}
                  className="w-full my-1 px-3 py-2 rounded-md border border-gray-400"
                >
                  <option disabled value="">
                    نوع الحساب
                  </option>
                  {[
                    { placeholder: "", value: "" },
                    { placeholder: " حامل سلاح", value: "weapon" },
                    { placeholder: "حامل لاسلكي", value: "wireless" },
                    { placeholder: "قيادة مركبة", value: "vehicle" },
                  ].map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.placeholder}
                    </option>
                  ))}
                </select>
                {touched.type && errors.type && (
                  <p className="text-red-600">{errors.type}</p>
                )}
              </div>
              {/*  */}
              {!!values.type && (
                <>
                  <div className="">
                    <label htmlFor="model">
                      {values.type === "weapon"
                        ? "نوع السلاح"
                        : values.type === "wireless"
                        ? "نوع اللاسلكي"
                        : "نوع السيارة"}
                    </label>
                    <input
                      placeholder=""
                      value={values.model}
                      type="text"
                      id="model"
                      name="model"
                      onChange={handleChange("model")}
                      className="w-full my-1 px-3 py-2 rounded-md border border-gray-400"
                    />
                    {touched.model && errors.model && (
                      <p className="text-red-600">{errors.model}</p>
                    )}
                  </div>
                  {/*  */}
                  <div className="">
                    <label htmlFor="number">
                      {values.type === "weapon"
                        ? "رقم السلاح"
                        : values.type === "wireless"
                        ? "رقم اللاسلكي"
                        : "رقم اللوحة"}
                    </label>
                    <input
                      placeholder="3453456"
                      value={values.number}
                      type="number"
                      id="number"
                      name="number"
                      onChange={handleChange("number")}
                      className="w-full my-1 px-3 py-2 rounded-md border border-gray-400"
                    />
                    {touched.number && errors.number && (
                      <p className="text-red-600">{errors.number}</p>
                    )}
                  </div>
                </>
              )}
              {/*  */}
              <div>
                <label htmlFor="expiry_date">الصلاحية</label>
                <select
                  name="expiry_date"
                  id="expiry_date"
                  onChange={handleChange("expiry_date")}
                  className="w-full my-1 px-3 py-2 rounded-md border border-gray-400"
                >
                  <option disabled value="">
                    الصلاحية
                  </option>
                  {[
                    { placeholder: "", value: "" },
                    { placeholder: "سنة", value: 1 },
                    { placeholder: "سنتان", value: 2 },
                    { placeholder: "اربع سنوات", value: 4 },
                  ].map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.placeholder}
                    </option>
                  ))}
                </select>
                {touched.expiry_date && errors.expiry_date && (
                  <p className="text-red-600">{errors.expiry_date}</p>
                )}
              </div>
            </div>
            <div className="my-5">
              {!!error && (
                <div className="alert alert-error mb-5">
                  <div className="flex-1  flex items-center text-red-700 bg-red-50 px-3 py-2 rounded-md">
                    <i className="bx bx-error-circle mx-2 text-xl"></i>
                    <label>{error}</label>
                  </div>
                </div>
              )}

              <button
                type="submit"
                disabled={isSubmitting}
                className="px-8 py-2 rounded-md text-white bg-primary"
                onClick={() => handleSubmit()}
              >
                {isSubmitting ? (
                  <i className="bx bx-loader-alt animate-spin"></i>
                ) : (
                  <span>تأكيد</span>
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FormScreen;
