import axios from "axios";
import { AuthData } from "../types";

// export const baseURL = "http://127.0.0.1:8000/api/v1/admin/";
export const baseURL = "https://ppc.bluespace.ly/api/";

class Axios {
  // Axios Instances ..
  Axios; // Authenticated Auto Refresh Axios

  currentToken = ""; // Current Token, Loaded from Local Storage

  constructor() {
    this.Axios = axios.create({ baseURL });

    this.getToken();

    // Add The Interceptors .. ( The Auth Interceptor is also used for logging .. )
    this.Axios.interceptors.request.use(this.requestAuthInterceptor);

    this.Axios.interceptors.response.use(
      (response) => response,
      this.requestErrorInterceptor
    );
  }

  async getToken() {
    this.currentToken = localStorage.getItem("token") || "";
  }

  requestAuthInterceptor = (request: any) => {
    request.headers.Authorization = "Bearer " + this.currentToken;
    return request;
  };

  requestErrorInterceptor = (error: any) => {
    return Promise.reject(error);
  };

  async saveAuthData(token: string) {
    this.currentToken = token;
    localStorage.setItem("token", token.toString());
  }
  async authenticate() {
    return this.Axios.get("me");
  }
  async login(authData: AuthData) {
    const response = await this.Axios.post("login", authData);
    if (response.data.data) {
      this.saveAuthData(response.data.data.token);
    }
    return response;
  }
  async logout() {
    const res = await this.Axios.post("logout");
    if (res) {
      localStorage.clear();
    }
    return res;
  }
  async getPermits(page: number, search: string, type: string) {
    return await this.Axios.get(
      `permits?page=${page}&search=${search}&type=${type}`
    );
  }
  async addPermits(values: any) {
    return await this.Axios.post(`permits`, values, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  async editPermits(values: any, id: number) {
    return await this.Axios.post(`permits/${id}`, values, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  async getSpecificPermits(id: number | string) {
    return await this.Axios.get(`permits/${id}`);
  }
  async deletePermit(id: number) {
    return await this.Axios.post(`permits/${id}`, { _method: "delete" });
  }
}

const API = new Axios();

export default API;
