import { SET_USER_DATE } from "../types";
import API from "../../services/API";
import { AuthData } from "../../types";

export const authenticate = () => async (dispatch: any) => {
  try {
    const { data } = await API.authenticate();
    if (data?.data) {
      dispatch({
        type: SET_USER_DATE,
        data: data?.data,
      });
    }
  } catch {}
};

export const login = (payload: AuthData) => async (dispatch: any) => {
  try {
    const { data } = await API.login(payload);
    if (data?.data?.user) {
      dispatch({
        type: SET_USER_DATE,
        data: data?.data?.user,
      });
    }
  } catch (error) {
    throw error;
  }
};

export const logoutFunc = () => async (dispatch: any) => {
  try {
    await API.logout();
    dispatch({
      type: SET_USER_DATE,
      data: null,
    });
  } catch (error) {
    throw error;
  }
};
