import { Navigate } from "react-router-dom";
import { useAppSelector } from "../store";

import Login from "../screens/Login";
import Layout from "../screens/Layout";
import Home from "../screens/Home";
import FormScren from "../screens/FormScren";

const Routes = () => {
  const userData = useAppSelector((state) => state.auth.userData);
  return [
    {
      path: "/",
      element: userData ? <Layout /> : <Navigate to="/login" />,
      // protected routes
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/form-screen",
          element: <FormScren />,
        },
        {
          path: "/form-screen/:id",
          element: <FormScren />,
        },
      ],
    },
    {
      // public routes
      path: "/login",
      element: !userData ? <Login /> : <Navigate to="/" />,
    },
    {
      // public routes
      path: "/forget-password",
      element: !userData ? <div></div> : <Navigate to="/" />,
    },
  ];
};

export default Routes;
