import React, { useState, useMemo } from "react";
import { useNavigate, Outlet, NavLink } from "react-router-dom";
import { useAppDispatch } from "../store";
import { logoutFunc } from "../store/actions/auth";

const Layout: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [toggle, setToggle] = useState(false);
  const [logingOut, setLogingOut] = useState(false);

  const links = useMemo(
    () => [
      { name: "الرئيسية", path: "/" },
      { name: "اضافة عضو", path: "/form-screen" },
    ],
    []
  );

  const logout = async () => {
    setLogingOut(true);
    await dispatch(logoutFunc());
    navigate("/login");
    setLogingOut(false);
  };

  return (
    <div className="flex w-full">
      {/* side bar */}
      <section
        className={`h-screen transition-all duration-300 transform ${
          toggle
            ? "w-3/4 absolute md:static z-50 sm:1/3 lg:w-1/4"
            : "w-0 md:w-1/6"
        }  bg-primary`}
      >
        <ul className={`${!toggle ? "hidden md:block" : ""} text-white p-5`}>
          <div className="flex items-center justify-between h-24 mb-5">
            {/* logo */}
            <img
              src="https://moi.gov.ly/wp-content/uploads/2021/09/Picture1.png"
              id="logo"
              className="object-contain cursor-pointer h-full w-24"
              alt=""
              onClick={() => navigate("/")}
            />
            <button className="md:hidden " onClick={() => setToggle(!toggle)}>
              <i className="bx bx-menu text-3xl text-white"></i>
            </button>
          </div>
          {links.map((item, index) => (
            <li key={index} className="my-3 text-lg">
              <NavLink
                className={({ isActive }) =>
                  `block px-3 py-2 rounded-lg hover:bg-gray-900 ${
                    isActive ? "bg-gray-800" : ""
                  } w-full text-right`
                }
                to={item.path}
              >
                {item.name}
              </NavLink>
            </li>
          ))}
          <li className="my-3 text-lg">
            <button
              onClick={logout}
              className="w-full flex items-center justify-between px-3 py-2"
            >
              <span>تسجيل الخروج</span>
              {logingOut && (
                <i className="bx bx-loader-alt bx-spin text-xl"></i>
              )}
            </button>
          </li>
        </ul>
      </section>
      {/* main content */}
      <section className="w-full md:w-10/12 relative shrink ">
        <nav className="flex items-center justify-start bg-primary h-24 w-full text-white">
          <button className="md:hidden mx-5" onClick={() => setToggle(!toggle)}>
            <i className="bx bx-menu text-3xl"></i>
          </button>
          <h1 className="text-2xl text-white font-medium">
            مصلحة الجوازات والجنسية وشؤون الاجانب
          </h1>
          <div></div>
        </nav>
        <main className="">
          <Outlet />
        </main>
      </section>
    </div>
  );
};

export default Layout;
