import { useState } from "react";
import { Formik, Form } from "formik";
import { loginSchema } from "../services/validation";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../store";
import { login } from "../store/actions/auth";

const Login: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [error, setError] = useState<string>("");

  return (
    <div className="h-screen flex items-center justify center">
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={loginSchema}
        onSubmit={async (
          values,
          { setSubmitting, setErrors, setFieldError }
        ) => {
          try {
            await dispatch(login(values));
            setSubmitting(false);
            navigate("/");
          } catch (err: any) {
            if (err.response.data.error) {
              setError(err.response.data.error);
            } else {
              setError("حدث خطأ ما الرجاء إعادة المحاولة لاحقا");
            }
            setSubmitting(false);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <div className="w-3/4 sm:w-1/2 lg:w-1/3 mx-auto">
            <div className="text-center">
              <img
                src="https://moi.gov.ly/wp-content/uploads/2021/09/Picture1.png"
                id="logo"
                className="object-contain w-24 sm:28 md:w-32 lg:w-36 mx-auto mb-2"
                alt=""
              />
              <h1 className="text-3xl font-medium">
                دولة ليبيا - ورازة الداخلية <br />
                <span className="text-2xl">
                  مصلحة الجوازات والجنسية وشؤون الاجانب
                </span>
              </h1>
            </div>
            <Form name="login" onSubmit={handleSubmit} autoComplete="off">
              <div className="my-5">
                <label className="text-xl text-gray-800">
                  البريد الإلكتروني
                </label>
                <input
                  placeholder="example@gmail.com"
                  value={values.email}
                  type="email"
                  onChange={handleChange("email")}
                  className="w-full my-1 px-3 py-2 rounded-md border border-gray-400"
                />
                {touched.email && errors.email && (
                  <p className="w-full text-red-600 my-2">{errors.email}</p>
                )}
              </div>

              <div className="my-5">
                <label className="text-xl text-gray-800">كلمة المرور</label>
                <input
                  placeholder="********"
                  value={values.password}
                  type="password"
                  onChange={handleChange("password")}
                  className="w-full my-1 px-3 py-2 rounded-md border border-gray-400"
                />
                {touched.password && errors.password && (
                  <p className="w-full text-red-600 my-2">{errors.password}</p>
                )}
              </div>

              <div>
                {!!error && (
                  <div className="alert alert-error mb-5">
                    <div className="flex-1  flex items-center text-red-700 bg-red-50 px-3 py-2 rounded-md">
                      <i className="bx bx-error-circle mx-2 text-xl"></i>
                      <label>{error}</label>
                    </div>
                  </div>
                )}
                <button
                  type="submit"
                  onClick={() => handleSubmit()}
                  disabled={isSubmitting}
                  className="px-5 py-2 rounded-md bg-primary text-white "
                >
                  {!isSubmitting ? (
                    <span>تسجيل الدخول</span>
                  ) : (
                    <span>جاري تسجيل الدخول</span>
                  )}
                </button>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default Login;
